import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  Layout as AntLayout, Menu,
} from 'antd';

import context from '../../context/context';

import { IContext, UserRoleEnum } from '../../Types';

const { Header, Content } = AntLayout;

const Layout: React.FC = ({ children }): JSX.Element => {
  const { user } = useContext(context) as IContext;

  return (
    <AntLayout className='layout' style={{ height: '100rem' }}>
      <Header>
        <div className='logo' />
        <Menu theme='dark' mode='horizontal'>
          <Menu.Item>
            <Link to='/'>Все сообщения</Link>
          </Menu.Item>
          <Menu.Item>
            <Link to='/my'>Мои сообщения</Link>
          </Menu.Item>
          {user.role === UserRoleEnum.superadmin && (
          <>
            <Menu.Item>
              <Link to='/answers'>Ответы</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to='/users'>Пользователи</Link>
            </Menu.Item>
          </>
          )}
        </Menu>
      </Header>
      <Content>{children}</Content>
    </AntLayout>
  );
};

export default Layout;
