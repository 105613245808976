/* eslint-disable no-param-reassign */
import React, { useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  Button, Upload, Modal, message, Input,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from 'react-draft-wysiwyg';
import { UploadFile } from 'antd/lib/upload/interface';

import context from '../../context/context';

import { IContext } from '../../Types';

const Answer: React.FC = (): JSX.Element => {
  const { token } = useContext(context) as IContext;

  const history = useHistory();

  const { id: questionId } = useParams<{ id: string }>();
  const [previewVisible, setPrviewVisible] = useState<boolean>(false);
  const [previewImage, setPrviewImage] = useState<string>('');
  const [previewTitle, setPrviewTitle] = useState<string>('');
  const [imageList, setImageList] = useState<UploadFile[]>([]);
  const [editorValue, setEditorValue] = useState<EditorState>(
    EditorState.createEmpty(),
  );
  const [goods, setGoods] = useState<string>('');

  const handleAnswer = async () => {
    const formData = new FormData();
    imageList.forEach((file) => formData.append('files', file.originFileObj as Blob));
    formData.append('message', stateToHTML(editorValue.getCurrentContent()));
    formData.append('goods', goods);

    try {
      await axios.post(
        `/api/answer/${questionId}`,
        formData,
        {
          headers: {
            authorization: token,
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      message.success('Ответ успешно отправлен');
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  const getBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as Blob) as string;
    }
    setPrviewImage(file.url || file.preview as string);
    setPrviewVisible(true);
    setPrviewTitle(file.name || (file.url as string).substring((file.url as string).lastIndexOf('/') + 1));
  };

  return (
    <div className='editor-container' style={{ padding: 40 }}>
      <Editor
        editorState={editorValue}
        toolbarClassName='toolbarClassName'
        editorStyle={{ height: 400, backgroundColor: 'white', padding: 10 }}
        wrapperClassName='wrapperClassName'
        editorClassName='editorClassName'
        onEditorStateChange={(value) => setEditorValue(value)}
      />
      <Input
        placeholder='Рекомендованные товары'
        style={{ marginTop: 10, marginBottom: 10, width: 500 }}
        value={goods}
        onChange={(e) => setGoods(e.target.value)}
      />
      <div className='editor-container-upload'>
        <Upload
          action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          listType='picture-card'
          fileList={imageList}
          onPreview={handlePreview}
          onChange={({ fileList }) => setImageList(fileList)}
          beforeUpload={() => false}
        >
          {imageList.length >= 8 ? null : (
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={() => setPrviewVisible(false)}
        >
          <img alt='example' style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
      <Button onClick={handleAnswer} style={{ marginTop: 20 }} type='primary'>
        Ответить
      </Button>
    </div>
  );
};

export default Answer;
