/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
import React from 'react';
import { Link } from 'react-router-dom';

import { IBatchQuestionsProps, QuestionStatusEnum } from './Types';

const BatchQuestions: React.FC<IBatchQuestionsProps> = ({ questions }): JSX.Element => (
  <div className='messages'>
    {!!questions.length &&
        questions.map(({ _id, plantName, message, createdAt, status }) => (
          <Link key={_id} to={`/question/${_id}`}>
            <div
              className='message'
              style={{
                width: '60%',
                margin: '20px auto',
                height: '150px',
                padding: '20px',
                boxShadow: '0px 0px 5px 0px rgb(0 0 0 / 75%)',
                cursor: 'pointer',
                backgroundColor: status === QuestionStatusEnum.resolved ? 'gray' : 'transparent',
                color: status === QuestionStatusEnum.resolved ? 'white' : 'inherit',
              }}
            >
              <div
                className='message-header'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <p>{plantName}</p>
                <p>{new Date(createdAt).toUTCString()}</p>
              </div>
              <div className='message-body'>{message.slice(0, 250)}</div>
            </div>
          </Link>
        ))}
  </div>
);

export default BatchQuestions;
