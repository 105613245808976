/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button, Input, List } from 'antd';
import axios from 'axios';
import React, { useContext, useState } from 'react';

import context from '../../context/context';
import { IContext } from '../../Types';

import { IUsersProps } from './Types';

const Users:React.FC<IUsersProps> = ({ users, setUsers }): JSX.Element => {
  const { token } = useContext(context) as IContext;

  const [userEmail, setUserEmail] = useState<string>('');

  const addUser = async () => {
    const { data } = await axios.post('/api/users/',
      { email: userEmail },
      {
        headers: {
          authorization: token,
        },
      });
    setUserEmail('');
    setUsers([...users, data]);
  };

  const deleteUser = async (id: string) => {
    await axios.delete(`/api/users/${id}`,
      {
        headers: {
          authorization: token,
        },
      });
    setUsers([...users.filter(({ _id }) => _id !== id)]);
  };

  return (
    <>
      <List
        style={{ width: 400, margin: 30 }}
        bordered
        dataSource={users}
        renderItem={({ email, _id }) => (
          <List.Item key={email}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>{email}</div>
              <p
                onClick={() => deleteUser(_id)}
                style={{ color: 'blue', cursor: 'pointer' }}
              >
                Удалить
              </p>
            </div>
          </List.Item>
        )}
      />
      <Input
        value={userEmail}
        onChange={(e) => setUserEmail(e.target.value)}
        style={{ width: 200, marginLeft: 30 }}
      />
      <Button onClick={addUser}>Добавить</Button>
    </>
  );
};

export default Users;
