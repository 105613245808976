import React, { useEffect, useState } from 'react';
import axios from 'axios';

import BatchQuestions from '../components/BatchQuestions/BatchQuestions';

import { IQuestion } from '../components/BatchQuestions/Types';

const PrimaryPage: React.FC = (): JSX.Element => {
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const getQuestions = async () => {
    try {
      const { data } = await axios.get('/api/questions');
      setQuestions(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const sse = new EventSource('/api/questions/sse', {
      withCredentials: true,
    });
    sse.onmessage = (res) => setQuestions(JSON.parse(res.data));
    sse.onerror = (err) => {
      console.error(err);
      sse.close();
    };
    getQuestions();
    return () => {
      sse.close();
    };
  }, []);

  return <BatchQuestions questions={questions} />;
};

export default PrimaryPage;
