import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import Users from '../components/Users/Users';

import context from '../context/context';

import { IContext, IUser } from '../Types';

const UsersPage: React.FC = (): JSX.Element => {
  const { token } = useContext(context) as IContext;

  const [users, setUsers] = useState<IUser[]>([]);

  const getUsers = async () => {
    try {
      const { data } = await axios.get('/api/users', {
        headers: {
          authorization: token,
        },
      });
      setUsers(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  return <Users users={users} setUsers={setUsers} />;
};

export default UsersPage;
