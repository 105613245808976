/* eslint-disable react/prop-types */
import React from 'react';
import { Modal } from 'antd';
import { GoogleLogin, GoogleLoginResponse } from 'react-google-login';
import axios from 'axios';

import { ILoginProps } from './Types';

const Login: React.FC<ILoginProps> = ({ handleSetUserData }): JSX.Element => {
  const responseGoogle = async (response: GoogleLoginResponse) => {
    try {
      const { data } = await axios.get('/api/auth/login', {
        headers: {
          google: response.accessToken,
        },
      });
      handleSetUserData(data);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Modal
      bodyStyle={{ display: 'flex', flexDirection: 'column' }}
      closable={false}
      footer={null}
      title='Вход'
      visible
    >
      <GoogleLogin
        clientId='35607603147-399fk912itjpn63bksbdigrkgtlc37bv.apps.googleusercontent.com'
        buttonText='Login'
        onSuccess={responseGoogle as any}
        onFailure={responseGoogle as (response: GoogleLoginResponse) => void}
        cookiePolicy='single_host_origin'
      />
    </Modal>
  );
};

export default Login;
