import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import Answer from '../components/Answer/Answer';
import Question from '../components/Question/Question';
import ReadyAnswer from '../components/ReadyAnswer/ReadyAnswer';

import { IAnswer, IContext } from '../Types';
import { IQuestion, QuestionStatusEnum } from '../components/BatchQuestions/Types';

import context from '../context/context';

const QuestionPage: React.FC = (): JSX.Element => {
  const { token } = useContext(context) as IContext;
  const { id: questionId } = useParams<{ id: string }>();
  const [question, setQuestion] = useState<IQuestion>();
  const [answer, setAnswer] = useState<IAnswer>();

  useEffect(() => {
    if (question?.status === QuestionStatusEnum.payed) {
      axios
        .get(`/api/questions/take/${questionId}`, {
          headers: {
            authorization: token,
          },
        })
        .catch(console.error);
    }
  }, [question]);

  useEffect(() => {
    axios.get(`/api/questions/${questionId}`, {
      headers: {
        authorization: token,
      },
    })
      .then(({ data }) => {
        setQuestion(data.question);
        setAnswer(data.answer);
      })
      .catch(console.error);
  }, [questionId]);

  return (
    <>
      {question
      && (
        <>
          <Question
            message={question?.message}
            plantName={question.plantName}
            pictures={question.pictures}
          />
          {answer
            ? (
              <ReadyAnswer
                message={answer.message}
                pictures={answer.pictures}
                adminEmail={answer.adminEmail}
              />
            )
            : <Answer /> }
        </>
      ) }
    </>
  );
};

export default QuestionPage;
