import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';

import BatchQuestions from '../components/BatchQuestions/BatchQuestions';

import context from '../context/context';

import { IContext } from '../Types';
import { IQuestion } from '../components/BatchQuestions/Types';

const AnswersPage: React.FC = (): JSX.Element => {
  const { token } = useContext(context) as IContext;

  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const getQuestions = async () => {
    try {
      const { data } = await axios.get('/api/questions/resolved', {
        headers: {
          authorization: token,
        },
      });
      setQuestions(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getQuestions();
  }, []);

  return <BatchQuestions questions={questions} />;
};

export default AnswersPage;
