import React, { useEffect, useState } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import axios from 'axios';

import Login from './components/Login/Login';
import Layout from './components/Layout/Layout';
import PrimaryPage from './pages/PrimaryPage';
import QuestionPage from './pages/QuestionPage';
import MyQuestionsPage from './pages/MyQuestionsPage';
import AnswersPage from './pages/AnswersPage';
import UsersPage from './pages/UsersPage';

import context from './context/context';

import { IUser, IUserData, UserRoleEnum } from './Types';

const App: React.FC = (): JSX.Element => {
  const [user, setUser] = useState<IUser>();
  const [userToken, setUserToken] = useState<string>('');

  const handleSetUserData = (data: IUserData) => {
    const { token, ...userData } = data;
    if (token) {
      setUserToken(token);
      setUser(userData);
      localStorage.setItem('token', token);
    }
  };

  useEffect(() => {
    axios
      .get('/api/auth/token', {
        headers: {
          authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => res.data)
      .then(handleSetUserData)
      .catch(console.error);
  }, []);

  return (
    <>
      {user ? (
        <context.Provider value={{ user, token: userToken }}>
          <BrowserRouter>
            <Switch>
              <Layout>
                <Route path='/' exact component={PrimaryPage} />
                <Route path='/my' exact component={MyQuestionsPage} />
                <Route path='/question/:id' component={QuestionPage} />
                {user.role === UserRoleEnum.superadmin && (
                  <>
                    <Route path='/answers' component={AnswersPage} />
                    <Route path='/users' component={UsersPage} />
                  </>
                )}
              </Layout>
            </Switch>
          </BrowserRouter>
        </context.Provider>
      ) : (
        <Login handleSetUserData={handleSetUserData} />
      )}
    </>
  );
};

export default App;
