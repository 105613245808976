import React from 'react';
import { Image } from 'antd';

import { IReadyAnswerProps } from './Types';

const ReadyAnswer: React.FC<IReadyAnswerProps> = ({ message, pictures, adminEmail }) => (
  <div className='question-container' style={{ padding: 40 }}>
    <p dangerouslySetInnerHTML={{ __html: message }} />
    {pictures.map((picture) => (
      <div key={picture} style={{ marginRight: 10, marginTop: 10 }}>
        <Image src={picture} width={200} />
      </div>
    ))}
    <p style={{ marginTop: 20 }}>
      Consultant:
      <a href={`mailto${adminEmail}`}>{adminEmail}</a>
    </p>
  </div>
);

export default ReadyAnswer;
