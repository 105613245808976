import React from 'react';
import { Image } from 'antd';

import { IQuestionProps } from './Types';

const Question: React.FC<IQuestionProps> = ({ message, plantName, pictures }) => (
  <div className='question-container' style={{ padding: 40 }}>
    <h3>{plantName}</h3>
    <p>{message}</p>
    {pictures.map((picture) => (
      <div key={picture} style={{ marginRight: 10, marginTop: 10 }}>
        <Image src={picture} width={200} />
      </div>
    ))}
  </div>
);

export default Question;
