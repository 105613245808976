export enum QuestionStatusEnum {
  new = 'new',
  payed = 'payed',
  pending = 'pending',
  resolved = 'resolved',
}

export interface IQuestion {
  _id: string;
  createdAt: string;
  message: string;
  plantName: string;
  status: QuestionStatusEnum;
  userId: string;
  pictures: string[]
}

export interface IBatchQuestionsProps {
  questions: IQuestion[];
}
