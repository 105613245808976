export enum UserRoleEnum {
  superadmin = 'superadmin',
  consultant = 'consultant',
}

export interface IUser {
  _id: string;
  email: string;
  role: UserRoleEnum
}

export interface IUserData extends IUser {
  token: string;
}

export interface IContext {
  user: IUser;
  token: string;
}

export interface IAnswer {
  message: string;
  pictures: string[];
  adminEmail: string;
}
